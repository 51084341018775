import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import * as baseService from "../../services/baseService";
import { baseStorageUrl, baseUrl } from "../../baseUrl";
import LanguageContext from "../../Contexts/LanguageContext";



const Categories = () => {
    const [mainCategories, setMainCategories] = useState([]);
    const {language} = useContext(LanguageContext)
    useEffect(() => {
      baseService
        .getMainCategories()
        .then((result) => {
          setMainCategories(result);
        })
        .catch((err) => console.log(err));
    }, []);
    return (
      <>
        <div
          className="page-banner-wrap bg-cover"
          style={{
            backgroundImage: "url(&quot;assets/img/page-banner.jpg&quot;)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-12">
                <div className="page-heading text-white">
                  <h1>{language == "en" ? "Products" : "Продукти"}</h1>
                </div>
                <div className="breadcrumb-wrap">
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={"/"}>
                          {language == "en" ? "Home" : "Начало"}
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {language == "en" ? "Products" : "Продукти"}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="portfolio-showcase-wrapper section-padding">
          <div className="row grid px-24">
            {mainCategories &&
              mainCategories.map((category, index) => (
                <div className="col-lg-4 col-md-6   " key={index}>
                  <div className="project-item-card">
                    <a
                      href={`${baseUrl}/category/${category.slug}`}
                      className="d-block popup-link"
                    >
                      <img
                        src={`${baseStorageUrl}/${category.image}`}
                        alt=""
                        className="category_img"
                      />
                    </a>
                    <div className="contents">
                      <a
                        href={`${baseUrl}/category/${category.slug}`}
                        className="project-link"
                      >
                        <i className="fal fa-plus" />
                      </a>
                      <span>{category.category_name[language]}</span>
                      <h3>
                        <a href={`${baseUrl}/category/${category.slug}`}>
                          {category.category_name[language]}
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </section>
      </>
    );
}

export default Categories;